/** @description URL base de la API general de clientes. */
export const APIBASEURL = process.env.REACT_APP_APIBASEURL

/** @description URL base de la API de la zona de administración. */
export const APIADMINBASEURL = process.env.REACT_APP_APIADMINBASEURL

/** @description URL base de la autenticación por OAuth. */
export const APIOAUTHURL = process.env.REACT_APP_APIOAUTHURL

export const APIREQUESTOPTIONS = {
  responseType: 'json',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

export const COLOR = '#409142'
export const OFFERCOLOR = { dark: '#fbbd08', light: '#fff7db' }
export const TRANSFERCOLOR = { dark: '#409142', light: '#eaf8ea' }
export const CHARTCOLORS = [
  // '#784212',
  '#935116',
  '#af601a',
  '#ca6f1e',
  '#e67e22',
  '#eb984e',
  '#f0b27a',
  '#f5cba7',
  '#fae5d3',
  '#fdf2e9',
]

export const PAGESIZE = 25
export const NRESULTS = 25

export const MENUOPTIONS = {
  home: { url: '', text: 'Resumen', isPublic: true },
  products: { url: 'productos', text: 'Productos', isPublic: true },
  management: {
    group: 'Gestión',
    options: {
      orders: { url: 'pedidos', text: 'Pedidos', isPublic: true },
      charges: { url: 'encargos', text: 'Encargos', isPublic: true },
      //refunds: { url: 'devoluciones', text: 'Solicitar devolución', isPublic: true },
      invoices: { url: 'facturas', text: 'Facturas' },
      narcotics: { url: 'estupefacientes', text: 'Estupefacientes' },
    },
  },
  request: { url: 'solicitudes', text: 'Devoluciones', isPublic: true },
  finance: { url: 'finanzas', text: 'Finanzas' },
  news: { url: 'noticias', text: 'Noticias' },
  offers: { url: 'ofertas', text: 'Ofertas', isPublic: true },
  clubs: { url: 'clubes', text: 'Clubes' },
  consumed: { url: 'consumidos', text: 'Artículos consumidos', isPublic: true },
  transfers: { url: 'transfers', text: 'Transfers', isPublic: true },
  communities: { url: 'comunidades', text: 'Comunidad'},
  /*help: { url: 'ayuda', text: 'Ayuda', isPublic: true },*/
}

export const MONTHS = {
  1: { sort: 'Ene', long: 'Enero' },
  2: { sort: 'Feb', long: 'Febrero' },
  3: { sort: 'Mar', long: 'Marzo' },
  4: { sort: 'Abr', long: 'Abril' },
  5: { sort: 'May', long: 'Mayo' },
  6: { sort: 'Jun', long: 'Junio' },
  7: { sort: 'Jul', long: 'Julio' },
  8: { sort: 'Ago', long: 'Agosto' },
  9: { sort: 'Sep', long: 'Septiembre' },
  10: { sort: 'Oct', long: 'Octubre' },
  11: { sort: 'Nov', long: 'Noviembre' },
  12: { sort: 'Dic', long: 'Diciembre' },
}

export const ENGMONTHS = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
}

export const IVATYPES = {
  '0.00': { sort: 'E', long: 'Exento' },
  0.04: { sort: 'S', long: 'Superred.' },
  '0.10': { sort: 'R', long: 'Reducido' },
  0.21: { sort: 'N', long: 'Normal' },
}
/*
export const WAREHOUSESCODES = {
  sa: '1000', // Santander.
  vi: '2000', // Vizcaya.
  ma: '5000', // Madrid.
  pa: '7000', // Palencia.
  as: '8000', // Asturias.
}

export const WAREHOUSES = {
  1: 'sa', // Santander.
  2: 'vi', // Vizcaya.
  5: 'ma', // Madrid.
  7: 'pa', // Palencia.
  8: 'as', // Asturias.
}

export const WAREHOUSESLETTERS = {
  sa: 'S', // Santander.
  vi: 'V', // Vizcaya.
  ma: 'M', // Madrid.
  pa: 'P', // Palencia.
  as: 'A', // Asturias.
}

export const WAREHOUSESNAMES = {
  sa: 'Santander', // Santander.
  vi: 'Vizcaya', // Vizcaya.
  ma: 'Madrid', // Madrid.
  pa: 'Palencia', // Palencia.
  as: 'Asturias', // Asturias.
}*/

export const SEMANTICDELIVERYDAYS = ['Hoy', 'Mañana', 'En 2 días', 'En 3 días', 'En 4 días', 'En 5 días']

export const ORDERTYPES = {
  NORMAL: 'Normal',
  ENCARGO: 'Encargo',
  OFERTA: 'Oferta',
  TRANSFER: 'Transfer',
  REPOSICION: 'Reposición',
  DEVOLUCION: 'Devolución',
  WEB: 'Web',
  // CARGOS: 'Cargos',
  // ABONOS: 'Abonos',
  'ABONO CLUB': 'Abono CLUB',
  'ABONO CEDIFA': 'Abono CEDIFA',
}
export const ORDER_INVOICED = {
  F: 'Facturado',
  N: 'No facturado',
}
// export const ORDERTYPES = {
//   ZABO: 'Ped. Abono',
//   ZCLU: 'Nota de Abono Club',
//   ZDEV: 'Devolución',
//   ZEXP: 'Ventas especiales',
//   ZFNA: 'Financiero N. Abono',
//   ZFNC: 'Financiero N. Cargo',
//   ZGA2: 'Gastos sin mov. stock',
//   ZGAS: 'Productos sensibles',
//   ZHOS: 'Serv. Hospitalario',
//   ZINT: 'Pedido de Intercamb.',
//   ZNA: 'Nota de abono',
//   ZNA4: 'NA R.Decreto 08/2010',
//   ZNA5: 'NA Rappel',
//   ZNAC: 'Abono Cedifa',
//   ZNC: 'Nota de cargo',
//   ZNC1: 'NC Facturación',
//   ZNC2: 'NC Capitalización',
//   ZNC3: 'NC Gastos (facturac)',
//   ZNC5: 'NC Rappel',
//   ZNC7: 'NC Serv.Logísticos',
//   ZNC8: 'NC Compras Min',
//   ZNNA: 'Normal',
//   ZNNS: 'Normal sin mov. stock',
//   ZNO: 'Normal',
//   ZOF1: 'Oferta 1',
//   ZOF2: 'Oferta 2',
//   ZOF3: 'Oferta 3',
//   ZOF4: 'Oferta 4',
//   ZOF5: 'Oferta 5',
//   ZOF6: 'Oferta 6',
//   ZOPL: 'Op. limpieza',
//   ZPE: 'Encargo',
//   ZPES: 'Encargo sin mov. stock',
//   ZPPS: 'Productos sensibles',
//   ZPRE: 'Reserva',
//   ZPVL: 'Transfer PVL',
//   ZPVS: 'ZPVL sin mov. stock',
//   ZRED: 'Reposición D.',
//   ZREN: 'Reposición N.',
//   ZREP: 'Reposición P.',
//   ZRES: 'Reposición S.',
//   ZRET: 'Repo Parafarmacia',
//   ZREV: 'Reposición',
//   ZREZ: 'Repo Aplazada',
//   ZSIN: 'Sinergias',
//   ZTR: 'Transfer',
//   ZTRD: 'Trf. Diferido',
//   ZTRS: 'TR sin mov. stock',
//   ZVAR: 'Varios sin mov. stock',
//   ZWEB: 'Web',
// }

export const ARTICLEGROUPS = {
  CLI: { long: 'Clínicos', short: 'Clínicos' },
  CON: { long: 'Consumibles', short: 'Consumibles' },
  DIE: { long: 'Dietética', short: 'Dietética' },
  DIEP: { long: 'Dietéticos financiados', short: 'Dietéticos fin' },
  ESP: { long: 'Especialidades', short: 'Especialidades' },
  GEN: { long: 'Genéricos', short: 'Genéricos' },
  INC: { long: 'Incontinencia SOE', short: 'Incontinencia SOE' },
  INF: { long: 'Informática', short: 'Informática' },
  MFI: { long: 'Margen escalonado', short: 'Margen esc' },
  OTR: { long: 'Otros', short: 'Otros' },
  PAR: { long: 'Parafarmacia', short: 'Parafarmacia' },
  PARP: { long: 'Parafarmacia PVP', short: 'Parafarmacia PVP' },
  FRI: { long: 'Frío', short: 'Frío' },
  EST: { long: 'Estupefacientes', short: 'Estupefacientes' },
}

export const TRANSFERCLIENTTYPES = {
  ALL: 'Todos',
  FARM: 'Farmacia',
  GRC: 'Grupo',
}

export const CHARGESSTATUS = {
  S: 'Solicitado',
  T: 'Tramitado',
  F: 'Finalizado',
}

export const REFUNDMOTIVES = [
  { value: "03",    text: "No recibido" },
  { value: "04",    text: "No apto para la venta" },
  { value: "05",    text: "Error de puesta" },
  { value: "06",    text: "No interesa" },
  //{ value: "07",    text: "Mal anotado" },
  //{ value: "08",    text: "Error en el precio" },
  //{ value: "09",    text: "Defecto de calidad" },
  //{ value: "10",    text: "Otros" },
  //{ value: "12",    text: "Género sobrante" },
  { value: "13",    text: "Próximo a caducar" },
];