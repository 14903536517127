import React, { lazy, Suspense, useContext } from 'react'
import { Router } from '@reach/router'
import { Loader } from 'semantic-ui-react'

import { UserContext, ProductsContext } from './Context'
import CacheLoader from './CacheLoader'
import CookiesPolicyBanner from './CookiesPolicyBanner'
import LazyLoadErrorBoundary from './LazyLoadErrorBoundary'
import ScreensDashboard from '../screens/Dashboard'

import { useCookies } from 'react-cookie'

// Code Splitting.
const ScreensUser = lazy(() => import('../screens/User'))
const ScreensPharmacy = lazy(() => import('../screens/Pharmacy'))
const ScreensCart = lazy(() => import('../screens/Cart'))
const ScreensProduct = lazy(() => import('../screens/Product'))
const ScreensOrder = lazy(() => import('../screens/Order'))
const ScreensRefund = lazy(() => import('../screens/Refund'))
const ScreensReturnRequest = lazy(() => import('../screens/ReturnRequest'))
const ScreensInvoice = lazy(() => import('../screens/Invoice'))
const ScreensNarcotic = lazy(() => import('../screens/Narcotic'))
const ScreensFinances = lazy(() => import('../screens/Finances'))
const ScreensPromotion = lazy(() => import('../screens/Promotion'))
const ScreensOnlinePromotion = lazy(() => import('../screens/OnlinePromotion'))
const ScreensCommunity = lazy(() => import('../screens/Community'))
const ScreensClub = lazy(() => import('../screens/Club'))
const ScreensConsumedArticle = lazy(() => import('../screens/ConsumedArticle'))
const ScreensCharge = lazy(() => import('../screens/Charge'))
const ScreensTransfer = lazy(() => import('../screens/Transfer'))
const ScreensHelp = lazy(() => import('../screens/Help'))
const ScreensCookies = lazy(() => import('../screens/Cookies'))
const ScreenNews = lazy(() => import('../screens/News'))
const ScreenOauth = lazy(() => import('../screens/Oauth'))

const SectionLoader = (props) => (
  <Loader active inline="centered" style={{ marginTop: '2rem' }} {...props} />
)

const Sections = () => {
  const { userId, userIsAdmin, userIsManager } = useContext(UserContext)
  const { productsStatus, offersStatus, transfersStatus, onlinePromotionsStatus } =
    useContext(ProductsContext)
  const [cookies] = useCookies(['acceptcookies'])

  return (
    <>
      {(productsStatus !== 'ok' || offersStatus !== 'ok' || transfersStatus !== 'ok') && (
        <CacheLoader
          productsStatus={productsStatus}
          offersStatus={offersStatus}
          transfersStatus={transfersStatus}
          onlinePromotionsStatus={onlinePromotionsStatus}
        />
      )}
      {(!cookies['acceptcookies'] || cookies['acceptcookies'] !== 'yes') && <CookiesPolicyBanner />}
      <LazyLoadErrorBoundary>
        <Suspense fallback={<SectionLoader />}>
          <Router primary={false}>
            {(userIsAdmin() || userIsManager()) && <ScreensUser key={userId} path="/usuarios/*" />}
            {(userIsAdmin() || userIsManager()) && (
              <ScreensPharmacy key={userId} path="/farmacia/*" />
            )}
            <ScreensCart key={userId} path="/carrito/*" />
            <ScreensProduct key={userId} path="/productos/*" />
            <ScreensOrder key={userId} path="/pedidos/*" />
            
            {(userIsAdmin() || userIsManager()) && (
              <ScreensInvoice key={userId} path="/facturas/*" />
            )}
            <ScreensNarcotic key={userId} path="/estupefacientes/*" />
            {(userIsAdmin() || userIsManager()) && (
              <ScreensFinances key={userId} path="/finanzas/*" />
            )}
            <ScreensPromotion key={userId} path="/ofertas/*" />
            <ScreensOnlinePromotion key={userId} path="/online/*" />
            {(userIsAdmin() || userIsManager()) && <ScreensCommunity key={userId} path="/comunidades/*" />}
            {(userIsAdmin() || userIsManager()) && <ScreensClub key={userId} path="/clubes/*" />}
            <ScreensConsumedArticle key={userId} path="/consumidos/*" />
            <ScreensCharge key={userId} path="/encargos/*" />
            <ScreensRefund key={userId} path="/devoluciones/*" />
            <ScreensReturnRequest key={userId} path="/solicitudes/*" />
            <ScreensTransfer key={userId} path="/transfers/*" />
            <ScreensHelp key={userId} path="/ayuda/*" />
            <ScreensCookies key={userId} path="/cookies" />
            <ScreenNews key={userId} path="/noticias/*" />
            <ScreenOauth key={userId} path="/authorize/*" />
            <ScreensDashboard key={userId} default />
          </Router>
        </Suspense>
      </LazyLoadErrorBoundary>
    </>
  )
}

export default Sections
